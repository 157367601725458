import ImgUser from "@/images/icon/icon--userThumbnail.svg?react"
import ImgBell from "@/images/icon/icon--bell.svg?react"
import { useCurrentUser } from "@/scripts/contexts/auth/context"
import { UserType } from "@/scripts/generated/graphql"
import { Center, ChevronIcon, Group, Menu, UnstyledButton } from "@mantine/core"

export const HeaderNav = () => {
	const notificationCount = 0 // TODO
	//const notificationCountText = notificationCount >= 100 ? "+99" : notificationCount
	//const notificationHistory = [] // TODO
	const [user] = useCurrentUser()
	return (
		<>
			<div className="mainHeader__body">
				<div className="mainHeader__bodyItem">
					{notificationCount > 0 && (
						<>
							<a
								href="@/scripts/contexts/common/components/HeaderNav#mainHeader__newsTooltip"
								className="mainHeader__bodyItemLink"
							>
								<Center
									sx={{
										height: 28,
									}}
								>
									<ImgBell />
								</Center>
							</a>

							<div id="mainHeader__newsTooltip" className="mainHeader__newsTooltip js__tooltip">
								<div className="mainHeader__newsTooltipBody">
									<ul className="mainHeader__newsLinklist list">
										{/*<x-newsListParts TODO*/}
										{/*:list='$news' noneMessage="新着のお知らせはありません" />*/}
									</ul>
									<div className="mainHeader__newsTooltipBodyButton">
										<a href="@/scripts/contexts/common/components/HeaderNav#" className="button">
											<span className="button__text">もっと見る</span>
										</a>
									</div>
								</div>
							</div>
						</>
					)}
					{notificationCount === 0 && (
						<span className="mainHeader__bodyItemLink">
							<UnstyledButton disabled>
								<Center
									sx={{
										width: 23.5,
									}}
								>
									<ImgBell />
								</Center>
							</UnstyledButton>
						</span>
					)}
				</div>

				<div className="mainHeader__bodyItem">
					<div className="mainHeader__userName">
						{user && (
							<p>
								{user.company_name} {user.name} 様
							</p>
						)}
					</div>
				</div>

				<div className="mainHeader__bodyItem">
					<Menu
						transitionProps={{ transition: "scale-y" }}
						withArrow
						offset={20}
						position="bottom-start"
						zIndex={
							// ヘッダー(CSS)の指定と同じ
							999999
						}
					>
						<Menu.Target>
							<UnstyledButton>
								<Group>
									<ImgUser />
									<Center>
										<ChevronIcon />
									</Center>
								</Group>
							</UnstyledButton>
						</Menu.Target>
						<Menu.Dropdown>
							{user && user.permission === UserType.Admin && (
								<>
									<Menu.Item component="a" href={"/admin/users"}>
										メンバーリスト
									</Menu.Item>
									<Menu.Divider />
								</>
							)}
							<Menu.Item component={"a"} href={"/logout"}>
								ログアウト
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				</div>
			</div>
		</>
	)
}
