import { useEffect } from "react"
import { onLive } from "./global"

/** ネットワークの状況が回復したら reset を実行します  */
export const useEffectOnLive = (reset: () => unknown) => {
	useEffect(() => {
		function handle(value: "UP" | "DOWN") {
			if (value === "UP") {
				reset()
			}
		}
		onLive.on(handle)
		return () => {
			onLive.off(handle)
		}
	}, [reset])
}
