import { Helmet } from "react-helmet-async"

const DEFAULT_PREFIX = "Tomastab"

/** ページタイトルを書き換えるコンポーネント
 */
export const PageTitle = (props: { title?: string; prefix?: string }) => {
	const { title, prefix = DEFAULT_PREFIX } = props

	return (
		<Helmet>
			<title key={"title"}>{title ? `${title} - ${prefix}` : prefix}</title>
		</Helmet>
	)
}
