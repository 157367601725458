import { useEffect } from "react"
import { onLive } from "../../networking/global"
import { setUnauthenticated } from "../context"
import { ForceLogoutModal } from "./force-logout-modal"

export const AuthProvider = (props: { children?: React.ReactNode }) => {
	useUnauthorizedEffect()
	return (
		<>
			{props.children}
			<ForceLogoutModal />
		</>
	)
}

const useUnauthorizedEffect = () => {
	// 認証エラーが発生した場合、ログアウトを強制する
	useEffect(() => {
		function handle(value: "UP" | "DOWN") {
			if (value === "DOWN") {
				setUnauthenticated({ type: "FORCE_LOGOUT" })
			} else {
				setUnauthenticated({ type: "RESET" })
			}
		}
		onLive.on(handle)
		return () => {
			onLive.off(handle)
		}
	}, [])
}
