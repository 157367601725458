import * as Sentry from "@sentry/core"
import { devtoolsExchange } from "@urql/devtools"
import { contextExchange } from "@urql/exchange-context"
import { refocusExchange } from "@urql/exchange-refocus"
import { Kind, type DocumentNode } from "graphql"
import { isTruthy } from "typesafe-utils"
import {
	cacheExchange,
	createClient,
	fetchExchange,
	mapExchange,
	type AnyVariables,
	type DocumentInput,
	type Exchange,
	type Operation,
	type OperationContext,
} from "urql"
import { handleUnauthenticated } from "./global"

const exchanges: Exchange[] = [
	devtoolsExchange,
	//import.meta.env.DEV && debugExchange,
	refocusExchange(),
	cacheExchange,
	contextExchange({
		getContext: async (operation) => {
			return {
				...operation.context,
				url: annotatedURL(operation),
			}
		},
	}),
	handleUnauthenticated,
	mapExchange({
		onError(error, operation) {
			Sentry.withScope((scope) => {
				scope.setTag("kind", operation.kind)
				scope.setExtra("operationName", getOperationName(operation.query))
				scope.setExtra("variables", operation.variables)
				Sentry.captureException(error)
			})
		},
	}),
	fetchExchange,
].filter(isTruthy)

// urql 4.x
// https://github.com/urql-graphql/urql/blob/af5b90b7bb9aec77638a44671e9cddff531c16e1/packages/core/src/utils/request.ts#L170C1-L176C3
const getOperationName = (query: DocumentNode): string | undefined => {
	for (const node of query.definitions) {
		if (node.kind === Kind.OPERATION_DEFINITION) {
			return node.name ? node.name.value : undefined
		}
	}
}

/** リクエストのURLにoperationNameを付与する */
const annotatedURL = (operation: Operation) => {
	// contextは使い回されるため、パラメーターが既に存在する場合を考慮してURLクラスを使用してます
	// 相対パスを処理する良い方法がわからなかったためダミーのbase URLを指定しています。
	const url = new URL(operation.context.url, "http://example.com")
	url.searchParams.set("op", getOperationName(operation.query) ?? "")
	return url.host === "example.com" ? `${url.pathname}${url.search}` : url.toString()
}

export const gqlClient = createClient({
	url: "/graphql",
	exchanges,
	suspense: true,
})

/** クエリを事前読み込みします
 *
 * loader function で使うための関数です
 **/
export const preloadQuery = async <V extends AnyVariables>({
	client = gqlClient,
	query,
	variables,
	context,
}: {
	client?: typeof gqlClient
	query: DocumentInput<unknown, V>
	variables?: V
	context?: Partial<OperationContext>
}) => {
	return await client
		.query(query, variables, context)
		.toPromise()
		.catch((e) => {
			console.warn("Failed to preload query", e)
		})
}
