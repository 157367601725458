import type { GenericMaterialSize } from "@/scripts/generated/graphql"

export const materialSizeGroup = (size: GenericMaterialSize) => {
	if (size.startsWith("DOUBLE_SIDED_")) {
		return "両面チラシ"
	}
	if (size.startsWith("SINGLE_SIDED_")) {
		return "片面チラシ"
	}
	return undefined
}

export const isDoubleSided = (size: GenericMaterialSize) => {
	return size.startsWith("DOUBLE_SIDED_")
}
