import { AuthProvider } from "@/scripts/contexts/auth/components/auth-provider"
import { ModalsProvider } from "@mantine/modals"
import { Notifications } from "@mantine/notifications"
import { Outlet, createRootRoute } from "@tanstack/react-router"
import { Suspense, lazy } from "react"
import { Provider as URQLProvider } from "urql"
import { PageTitle } from "../components/common-ui/PageTitle"
import { ErrorRouteFallback } from "../components/common-ui/error-fallback"
import { gqlClient } from "../contexts/networking/graphql-client"

export const Route = createRootRoute({
	component: () => {
		return (
			<SystemsProvider>
				<ModalsProvider>
					<AuthProvider>
						<Notifications position="bottom-center" />
						<PageTitle />
						<Outlet />
						<Suspense>
							<TanStackRouterDevtools />
						</Suspense>
					</AuthProvider>
				</ModalsProvider>
			</SystemsProvider>
		)
	},
	errorComponent: ErrorRouteFallback,
})

const SystemsProvider = (props: { children?: React.ReactNode }) => (
	<URQLProvider value={gqlClient}>{props.children}</URQLProvider>
)

const TanStackRouterDevtools = import.meta.env.DEV
	? lazy(() =>
			// Lazy load in development
			import("@tanstack/router-devtools").then((res) => ({
				default: res.TanStackRouterDevtools,
				// For Embedded Mode
				// default: res.TanStackRouterDevtoolsPanel
			}))
		)
	: () => null // Render nothing in production
