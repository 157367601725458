import { useCurrentUser } from "@/scripts/contexts/auth/context"
import { gql } from "@/scripts/generated"
import { LoginDocument } from "@/scripts/generated/graphql"
import { Button, Divider, Group, Modal, Paper, Stack, Text } from "@mantine/core"
import { useCallback } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { PasswordInput, TextInput } from "react-hook-form-mantine"
import { useMutation } from "urql"
import { getLastLogin } from "../remember"

export const ForceLogoutModal = () => {
	// FIXME: すべてのGraphQLリクエストが失敗する状況ではこのモーダルが出てしまう
	const [user] = useCurrentUser()
	return (
		<Modal title="ログアウト" opened={!user.id} centered onClose={() => {}}>
			<LoginForm />
		</Modal>
	)
}

const LoginForm = () => {
	const form = useForm({
		defaultValues: {
			email: getLastLogin(),
			password: "",
		},
	})
	const [loginState, execute] = useMutation(LoginDocument)
	const handleSubmit = useCallback(
		async (values: {
			email: string
			password: string
		}) => {
			await execute({
				email: values.email,
				password: values.password,
			})
		},
		[execute]
	)

	return (
		<Stack>
			<Text>再度ログインしてください。</Text>
			<FormProvider {...form}>
				<Paper component="form" onSubmit={form.handleSubmit(handleSubmit)}>
					<Stack>
						<TextInput required name="email" label="メールアドレス" placeholder="メールアドレス" />
						<PasswordInput name="password" required label="パスワード" placeholder="パスワード" />
						<Divider />
						<Group position="right">
							<Button type="submit" loading={loginState.fetching}>
								ログイン
							</Button>
						</Group>
					</Stack>
				</Paper>
			</FormProvider>
		</Stack>
	)
}

gql(/* GraphQL */ `
    mutation Login($email: String!, $password: String!) {
      login(input: { email: $email, password: $password }) {
        __typename
        user {
          __typename
          id
        }
      }
    }
  `)
