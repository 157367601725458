import type { DataboxGridPageListQueryVariables, DataboxGridPageQueryVariables } from "@/scripts/generated/graphql"
import { useMemo } from "react"
import { isTruthy } from "remeda"
import * as v from "valibot"
import type { DataboxGridPageProps } from "./DataboxGridPage"

export const DataboxGridPageSearchSchema = v.object({
	name: v.optional(v.fallback(v.string(), "")),
})

export const getDataboxViewId = ({
	folderId,
	clientId,
}: Partial<Pick<DataboxGridPageProps, "clientId" | "folderId">>) => {
	return folderId === "root" ? clientId : folderId
}

export const useDataboxGridPageRoute = (props: Pick<DataboxGridPageProps, "clientId" | "folderId" | "search">) => {
	const folderId = props.folderId
	const clientId = props.clientId

	// NOTE: ページ遷移時にprefetchしたいなー
	const { name: searchQuery } = props.search ?? {}
	const isSearch = isTruthy(searchQuery)

	const databoxViewId = getDataboxViewId({
		folderId,
		clientId,
	})
	const metadataQueryVariable: DataboxGridPageQueryVariables | null = databoxViewId
		? {
				id: databoxViewId,
			}
		: null
	const listQueryVariable: DataboxGridPageListQueryVariables | null = useMemo(
		() =>
			databoxViewId
				? {
						id: databoxViewId,
						after: "",
						first: 12,
						search: isSearch ? searchQuery : null,
						includeDescendant: isSearch,
					}
				: null,
		[isSearch, searchQuery, databoxViewId]
	)

	return {
		folder_id: folderId,
		client_id: clientId,
		databoxViewId,
		metadataQueryVariable,
		listQueryVariable,
		isSearch,
		searchQuery,
	}
}
