import type { FallbackRender } from "@sentry/react"
import type { ErrorRouteComponent } from "@tanstack/react-router"
import * as R from "remeda"
import { CombinedError } from "urql"

const ErrorDisplay = (props: {
	data: [string, string][]
}) => {
	return (
		<div
			style={{
				padding: "1em",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				minHeight: "100vh",
				background: "#eee",
			}}
		>
			<div
				style={{
					// display: "flex",
					// flexDirection: "column",
					// alignItems: "center",
					// justifyContent: "center",
					minWidth: 300,
					background: "#fff",
					padding: "2em",
					fontSize: "16px",
					borderRadius: 20,
					color: "#474747",
					lineHeight: "1.5",
				}}
			>
				<h1 style={{ textAlign: "center", fontSize: "24px", marginBottom: "1em" }}>このページはご利用いただけません</h1>
				<p>
					ご迷惑をおかけしております。
					<br />
					予期しないエラーが発生したため、ご利用することができません。
				</p>
				<hr />
				<ul style={{ fontSize: "0.8em", color: "#777" }}>
					{props.data.map(([key, value]) => (
						<li key={key}>
							{key}: {value}
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}

export const ErrorFallback: FallbackRender = ({ eventId, error }) => {
	const msg =
		typeof error === "object" && error !== null && "message" in error && typeof error.message === "string"
			? error.message
			: "不明なエラー"

	return (
		<ErrorDisplay
			data={[
				["URL", window.location.href],
				["Error ID", eventId],
				["Message", msg],
			]}
		/>
	)
}

export const ErrorRouteFallback: ErrorRouteComponent = ({ error }) => {
	const msg = typeof error.message === "string" ? error.message : "不明なエラー"
	if (error instanceof CombinedError) {
		// FIXME: コードでやりたい
		if (R.find(error.graphQLErrors, (e) => e.extensions?.category === "authorization")) {
			return <>ログアウトされました</>
		}
	}

	return (
		<ErrorDisplay
			data={[
				["URL", window.location.href],
				["Message", msg],
			]}
		/>
	)
}
