import * as v from "valibot"

//type TRoute = MakeGenerics<{
//	Search: {
//		name?: string
//	}
//}>
export const DataboxClientGridPageSearchSchema = v.object({
	name: v.optional(v.fallback(v.string(), "")),
})
