import { isString } from "remeda"
import * as v from "valibot"

export const MaterialShowSearchQuerySchema = v.object({
	// フォーカスするメッセージのDbId
	histories_id: v.optional(v.number()),
	accordion: v.optional(
		//v.pipe(
		v.fallback(v.string(), "")
		// FIXME: valibotでやってみたい
		//v.transform((v) => {
		//	return v
		//		?.split(",")
		//		.map((vv) => vv.trim())
		//		.filter((vv) => vv)
		//		.join(",")
		//})
		//)
		// v.optional(v.array(v.union([v.literal("info"), v.literal("message")])))
	),
})

export const parseAccordionQuery = (
	query: v.InferOutput<typeof MaterialShowSearchQuerySchema>
): ("info" | "message")[] => {
	const { accordion, histories_id } = query

	let accordionInfo = accordion?.includes("info")
	let accordionMessage = accordion?.includes("message")
	// 未指定時はどちらも開く
	if (!accordionInfo && !accordionMessage) {
		accordionInfo = true
		accordionMessage = true
	}
	if (histories_id) {
		accordionMessage = true
	}

	const accoorionValues = [accordionInfo ? "info" : false, accordionMessage ? "message" : false].filter(isString) as (
		| "info"
		| "message"
	)[]
	return accoorionValues
}
