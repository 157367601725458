import { SidebarNav, type SidebarNavProps } from "@/scripts/components/layout/SidebarNav"
import ImgIconImg from "@/images/icon/icon--img.svg?react"
import ImgPeople from "@/images/icon/icon--group.svg?react"
import React from "react"
import { useAppShellStyle } from "@/scripts/components/layout/ApplicationLayout"

export const ClientPageSidebarNav: SidebarNavProps["sections"] = [
	{
		t: "section",
		key: "materials",
		label: "販促物依頼",
		labelIcon: <ImgIconImg />,
		items: [
			{
				t: "item",
				label: "進行中の販促物依頼",
				key: "ongoing",
				match: "/client/material",
			},
			{
				t: "item",
				label: "完了した販促物依頼",
				key: "done",
				match: "/client/material/completed",
			},
		],
	},
	{
		t: "section",
		key: "human-resources",
		label: "人材依頼",
		labelIcon: <ImgPeople />,
		items: [
			{
				t: "item",
				label: "進行中の人材依頼",
				key: "ongoing",
				match: "/client/human-resource",
			},
			{
				t: "item",
				label: "完了した人材物依頼",
				key: "done",
				match: "/client/human-resource/completed",
			},
		],
	},
]

export const ClientPageSidebarNavButtons: SidebarNavProps["itemsAfterSections"] = []

export const ClientPageSidebarFooterButtons: SidebarNavProps["footerItems"] = [
	{
		t: "item",
		key: "material-add",
		label: "販促物依頼",
		variant: "button",
		match: { to: "/client/material/add" },
	},
	{
		t: "item",
		key: "data-box",
		label: "データBOX",
		variant: "button",
		match: { to: "/client/databox" },
	},
]
