import type { Client, Exchange, Operation } from "@urql/core"
import { composeExchanges } from "@urql/core"
import { eventmit } from "eventmit"
import { CombinedError, mapExchange } from "urql"
import { pipe, tap } from "wonka"

/** unauthenticated なエラーの場合は復帰した時にリクエストを再実行する, また auth の状態に反映する */
export const unauthenticatedExchange = (): {
	exchange: Exchange
	signal: (ok: boolean) => unknown
	onLive: ReturnType<typeof eventmit<"UP" | "DOWN">>
} => {
	let activeClient: Client
	let wasOK = false
	const watchedOperations = new Map<number, Operation>()
	const observedOperations = new Map<number, number>()
	const onLive = eventmit<"UP" | "DOWN">()

	function signal(ok: boolean) {
		if (!activeClient) return

		if (ok === wasOK) return
		wasOK = ok

		if (ok) {
			onLive.emit("UP")
		} else {
			onLive.emit("DOWN")
		}

		if (!ok) return
		// biome-ignore lint/complexity/noForEach: <explanation>
		watchedOperations.forEach((op) => {
			activeClient.reexecuteOperation(
				activeClient.createRequestOperation("query", op, {
					...op.context,
					requestPolicy: "cache-and-network",
				})
			)
		})
	}

	const map = mapExchange({
		onResult(result) {
			if (!result.error) {
				signal(true)
			}
		},
		onError(error) {
			if (error instanceof CombinedError) {
				if (error.message.includes("Unauthenticated")) {
					signal(false)
					return
				}
			}
		},
	})

	const exchange: Exchange =
		({ client, forward }) =>
		(ops$) => {
			activeClient = client

			if (typeof window === "undefined") {
				return forward(ops$)
			}

			const processIncomingOperation = (op: Operation) => {
				if (op.kind === "query" && !observedOperations.has(op.key)) {
					observedOperations.set(op.key, 1)
					watchedOperations.set(op.key, op)
				}

				if (op.kind === "teardown" && observedOperations.has(op.key)) {
					observedOperations.delete(op.key)
					watchedOperations.delete(op.key)
				}
			}

			return forward(pipe(ops$, tap(processIncomingOperation)))
		}

	return {
		exchange: composeExchanges([exchange, map]),
		signal,
		onLive,
	}
}
