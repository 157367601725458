export const rememberLastLogin = (email: string) => {
	localStorage.setItem("last-login", JSON.stringify({ email }))
}

export const getLastLogin = () => {
	try {
		const item = localStorage.getItem("last-login")
		return item ? JSON.parse(item).email : null
	} catch (e) {
		return null
	}
}
