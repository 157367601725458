import {
	ApplicationLayout,
	Content,
	DetailFooterWrap,
	Footer,
	Header,
	Main,
	Sidebar,
} from "@/scripts/components/layout/ApplicationLayout"
import type React from "react"
import { ClientPageSidebarFooterButtons, ClientPageSidebarNav, ClientPageSidebarNavButtons } from "./Sidebar"
import { HeaderNav } from "./HeaderNav"
import { DataboxSidebar } from "@/scripts/contexts/databox/components/DataboxSidebar"
import { Box } from "@mantine/core"
import { useMatchRoute } from "@tanstack/react-router"

export const Layout = (props: React.PropsWithChildren<{}>) => {
	const matchRoute = useMatchRoute()
	return (
		<ApplicationLayout>
			{matchRoute({
				to: "/client/databox",
				fuzzy: true,
			}) ? (
				<DataboxSidebar footerItems={ClientPageSidebarFooterButtons} />
			) : (
				<Sidebar
					sections={ClientPageSidebarNav}
					itemsAfterSections={ClientPageSidebarNavButtons}
					footerItems={ClientPageSidebarFooterButtons}
				/>
			)}
			<Main>
				<Header>
					<HeaderNav />
				</Header>
				<Box sx={{ width: "100%", height: "100%", position: "relative" }}>
					{props.children}
					<DetailFooterWrap />
				</Box>
			</Main>
		</ApplicationLayout>
	)
}

export const LayoutContent = Content
export const LayoutFooter = Footer
