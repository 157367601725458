import { Group, ThemeIcon, type GroupProps, type ThemeIconProps } from "@mantine/core"
import type React from "react"

/** アイコン付きラベル */
export const LabelWithIcon = ({
	...props
}: {
	icon: React.ReactNode
	_icon?: Partial<ThemeIconProps>
	children: React.ReactNode
	spacing?: GroupProps["spacing"]
}) => (
	<Group spacing={props.spacing ?? 0}>
		<ThemeIcon {...props._icon}>{props.icon}</ThemeIcon>
		{props.children}
	</Group>
)
