// Build something cool. <3
import React from "react"
import ReactDOM from "react-dom/client"
import * as Sentry from "@sentry/react"
import { App } from "./entrypoints/App"
import { CombinedError } from "urql"
import { registerMPAReactEntrypoint } from "./mpa-react-loader"

console.log("😄 loading...")
const sentryInitialized = !!Sentry.getClient()
if (!import.meta.env.DEV) {
	if (!sentryInitialized) {
		Sentry.init({
			dsn: "https://5a6a6b9fd317493cbbd272c5e6bf8e00@o1143709.ingest.sentry.io/6204639",
			release: import.meta.env.VITE_APP_VERSION,
			beforeSend(event, hint) {
				const err = hint.originalException
				if (err instanceof CombinedError) {
					const messages = []
					if (err.networkError) {
						messages.push(err.networkError.message)
					}
					if (err.graphQLErrors) {
						messages.push(...err.graphQLErrors.map((e) => e.message))
					}
					event.message = messages.join(", ")
				}
				return event
			},
		})
		console.log("Sentry enabled", {
			release: import.meta.env.VITE_APP_VERSION,
		})
	}
} else {
	if (!sentryInitialized) {
		console.log("Sentry disabled", {
			release: import.meta.env.VITE_APP_VERSION,
		})
	}
}

registerMPAReactEntrypoint({
	"picture-viewer": () => import("./entrypoints/PictureViewer").then((m) => m.PictureViewer),
})

// SPA用のエントリーポイント
const root = document.getElementById("spa-app") as HTMLElement
if (root) {
	console.log("⚡ rendering...")
	ReactDOM.createRoot(root).render(
		<React.StrictMode>
			<App />
		</React.StrictMode>
	)
}
