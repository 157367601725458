import { LabelWithIcon } from "@/scripts/components/common-ui/LabelWithIcon"
import type { LinkAnchor } from "@/scripts/components/common-ui/types"
import {
	Accordion,
	Box,
	Center,
	ChevronIcon,
	createStyles,
	type CSSObject,
	Group,
	Text,
	UnstyledButton,
} from "@mantine/core"
import { Link } from "@tanstack/react-router"
import type React from "react"
import type { ComponentProps } from "react"

/** サイドバーに表示するセクション
 *
 * セクションはネストしてItemを持つことができる
 */
export type SidebarNavItemSection = {
	t: "section"
	key: string
	label: string
	labelIcon?: React.ReactNode
	items: SidebarNavButtonItem[]
}

/** サイドバーに表示するボタン
 *
 * 普通はセクションに入れるが、直接トップレベルに表示することも可能
 */
export type SidebarNavButtonItem = {
	t: "item"
	key: string
	label: string
	labelIcon?: React.ReactNode
	variant?: "button" | "normal"
	/** 遷移先: 文字列の場合はサーバサイドルーティングで、NavigateOptionsの場合はクライアントサイドルーティングです */
	match?: LinkAnchor
}

export type SidebarNavProps = {
	/** 表示するセクション */
	sections?: SidebarNavItemSection[] | React.ReactNode

	/** 表示するボタン
	 *
	 * 一番上に表示するボタン
	 */
	itemsAfterSections?: SidebarNavButtonItem[] | React.ReactNode

	/**
	 * フッターに表示するボタン
	 */
	footerItems?: SidebarNavButtonItem[] | React.ReactNode
}

/** サイドバー */
export const SidebarNav = (props: SidebarNavProps) => {
	const { classes, cx } = useSidebarStyles()
	const { topSection, bottomSection, wrapper, ...accordionClasses } = classes
	const [menuSections, menuSectionElement] = Array.isArray(props.sections)
		? [props.sections, null]
		: [null, props.sections]
	const [menuAfterSections, menuAfterElement] = Array.isArray(props.itemsAfterSections)
		? [props.itemsAfterSections, null]
		: [null, props.itemsAfterSections]
	const [menuFooterItems, menuFooterElement] = Array.isArray(props.footerItems)
		? [props.footerItems, null]
		: [null, props.footerItems]

	return (
		<div className={cx(wrapper)}>
			<Box className={cx(topSection)}>
				{menuSections && (
					<Accordion
						classNames={{
							...accordionClasses,
						}}
						chevronPosition={"right"}
						multiple
					>
						{menuSections.map((section) => (
							<Accordion.Item key={section.key} value={section.key}>
								<Accordion.Control>
									<LabelWithIcon icon={section.labelIcon} _icon={{ size: 32 }}>
										{section.label}
									</LabelWithIcon>
								</Accordion.Control>
								<Accordion.Panel>
									{section.items.map((item) => (
										<MenuLink key={item.key} label={item.label} match={item.match} />
									))}
								</Accordion.Panel>
							</Accordion.Item>
						))}
					</Accordion>
				)}
				{menuSectionElement}
				{menuAfterSections?.map((item) => (
					<LargeMenuLink
						key={item.key}
						label={
							<LabelWithIcon icon={item.labelIcon} _icon={{ size: 32 }}>
								{item.label}
							</LabelWithIcon>
						}
						variant={item.variant}
						match={item.match}
					/>
				))}
				{menuAfterElement}
			</Box>
			<Box className={cx(bottomSection)}>
				{menuFooterItems?.map((item) => (
					<LargeMenuLink
						key={item.key}
						label={
							item.labelIcon ? (
								<LabelWithIcon icon={item.labelIcon} _icon={{ size: 32 }}>
									{item.label}
								</LabelWithIcon>
							) : (
								<Text>{item.label}</Text>
							)
						}
						variant={item.variant}
						match={item.match}
					/>
				))}
				{menuFooterElement}
			</Box>
		</div>
	)
}

export const MenuLink = ({ label, match }: { label: React.ReactNode } & Pick<SidebarNavButtonItem, "match">) => {
	const { classes } = useMenuLinkStyles()
	const isExternal = typeof match === "string"

	const buttonProps: ComponentProps<typeof UnstyledButton> = isExternal
		? { component: "a", href: match }
		: { component: Link, ...match }

	return (
		<UnstyledButton {...buttonProps} className={classes.root}>
			<Group position={"apart"}>
				<Text size="sm">{label}</Text>
				<Center
					sx={{
						transform: "rotate(-90deg)",
					}}
				>
					<ChevronIcon />
				</Center>
			</Group>
		</UnstyledButton>
	)
}

export const LargeMenuLink = ({
	label,
	match,
	variant = "normal",
}: { label: React.ReactNode; variant?: "normal" | "button" } & Pick<SidebarNavButtonItem, "match">) => {
	const { classes } = useLargeMenuItemStyles({ variant })
	const isExternal = typeof match === "string"
	const buttonProps: ComponentProps<typeof UnstyledButton> = isExternal
		? { component: "a", href: match }
		: { component: Link, ...match }

	return (
		<UnstyledButton className={classes.root} {...buttonProps}>
			<span className={classes.label}>{label}</span>
		</UnstyledButton>
	)
}

const useSidebarStyles = createStyles((theme) => ({
	wrapper: {
		display: "flex",
		flexGrow: 1,
		flexDirection: "column",
		paddingBottom: theme.spacing.xl,
	},
	topSection: {
		flexGrow: 1,
		display: "flex",
		flexDirection: "column",
	},
	bottomSection: {
		marginTop: theme.spacing.xs,
		display: "flex",
		flexDirection: "column",
	},
	item: {
		borderBottomColor: theme.colors.blueGray[7],
	},
	label: {
		color: "white",
		fontWeight: "bold",
		fontSize: theme.fontSizes.md,
		textAlign: "center",
	},
	content: {
		paddingInline: 0,
		paddingBlockEnd: 0,
	},
	chevron: {
		marginInlineStart: theme.spacing.xs,
	},
	control: {
		color: "white",
		paddingInline: theme.spacing.lg,
		backgroundColor: "transparent",
		"&:hover": {
			backgroundColor: "inherit",
		},
	},
	icon: {
		color: "white",
	},
}))

const useMenuLinkStyles = createStyles((theme) => {
	return {
		root: {
			ref: "root",
			display: "block",
			width: "100%",
			padding: `calc(${theme.spacing.sm} / 2)`,
			paddingInlineStart: theme.spacing.lg,
			paddingInlineEnd: theme.spacing.lg,
			borderRadius: theme.radius.sm,
			color: "white",
			backgroundColor: theme.colors.blueGray[7],
			"&+ &": {
				marginTop: 1,
			},
		},
	}
})

type SidebarLargeMenuItemVariant = "normal" | "outline" | "fill" | "button"

const useLargeMenuItemStyles = createStyles((theme, { variant }: { variant: SidebarLargeMenuItemVariant }) => {
	if (variant === "normal") {
		return {
			root: {
				color: "white",
				padding: theme.spacing.xs,
				paddingInlineStart: theme.spacing.lg,
				paddingInlineEnd: theme.spacing.lg,
				paddingBlock: theme.spacing.md,
				width: "100%",
				height: "auto",
			},
			label: {
				fontSize: theme.fontSizes.md,
				fontWeight: "bold",
			},
		}
	}

	const margins = {
		marginInline: theme.spacing.xl,
		marginBlockStart: theme.spacing.xs,
	} as CSSObject

	const fill = {
		root: {
			textAlign: "center",
			background: theme.white,
			color: theme.black,
			borderRadius: theme.radius.sm,
			padding: theme.spacing.xs,
			paddingInline: theme.spacing.lg,
			paddingBlock: theme.spacing.xs,
			height: "auto",
			...margins,
		},
		label: {
			fontSize: theme.fontSizes.md,
			fontWeight: "normal",
		},
	} as Record<"root" | "label", CSSObject>

	const outline = {
		root: {
			color: "white",
			textAlign: "center",
			border: "1px solid white",
			borderRadius: theme.radius.sm,
			padding: theme.spacing.xs,
			paddingInline: theme.spacing.lg,
			paddingBlock: theme.spacing.xs,
			height: "auto",
			...margins,
		},
		label: {
			fontSize: theme.fontSizes.md,
			fontWeight: "bold",
		},
	} as Record<"root" | "label", CSSObject>

	if (variant === "button") {
		return {
			root: {
				...outline.root,
				"&.active": {
					...fill.root,
				},
			},
			label: {
				...outline.label,
				"&.active": {
					...fill.label,
				},
			},
		}
	}

	if (variant === "fill") {
		return fill
	}

	if (variant === "outline") {
		return outline
	}

	return {
		root: {
			...margins,
		},
		label: {
			fontSize: theme.fontSizes.md,
			fontWeight: "bold",
		},
	}
})
