import { GenericMaterialSize } from "@/scripts/generated/graphql"
import dayjs from "dayjs"
import dayjsBusinessTime from "dayjs-business-time"
import "dayjs/locale/ja"
import { isDoubleSided } from "../model/generic"

export const getMinDeliveryDate = (requirePrinting: boolean, material: GenericMaterialSize, now = dayjs()) => {
	let n = now

	// 営業時間外の場合は翌日の営業開始時間に設定
	if (!n.isBusinessTime()) {
		n = n.nextBusinessTime()
	}

	let days: number
	if (requirePrinting) {
		if (material === GenericMaterialSize.TissueFlyer || isDoubleSided(material)) {
			days = 4
		} else {
			days = 3
		}
	} else {
		days = 2
	}

	return n
		.addBusinessDays(days)
		.addBusinessDays(1)
		.set("hours", 0)
		.set("minutes", 0)
		.set("seconds", 0)
		.set("milliseconds", 0)
}

export const initDayjs = () => {
	dayjs.locale("ja")
	dayjs.extend(dayjsBusinessTime)
	const normal = { start: "00:00:00", end: "19:00:00" }
	dayjs.setHolidays([])
	dayjs.setBusinessTime({
		sunday: null,
		monday: [normal],
		tuesday: [normal],
		wednesday: [normal],
		thursday: [normal],
		friday: [normal],
		saturday: null,
	})

	return dayjs
}
